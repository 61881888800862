<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Register-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <!-- Brand logo-->
        <b-link style="position: absolute; top:0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt="" class="logo">
        </b-link>

        <b-col
          sm="8"
          md="6"
          lg="5"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="text-light text-center mb-5">
            <p style="font-size: 1.5em; padding-right: 1.8rem !important">Please enter some of your details</p>
          </b-card-title>
          <div class="w-100 text-center" v-if="this.$store.state.avatar" style="padding-right: 1.8rem !important">
            <img
              :src="previewImage? previewImage : this.$store.state.avatar"
              alt="" class="m-auto cursor-pointer"
              @click="clickEvent()"
              style="max-width: 200px"
            >
            <button class="btn btn-primary d-block mx-auto mt-1" @click="clickEvent()">Upload Image</button>
              <!-- accept=".jpg, .jpeg, .png, .gif" -->
            <input type="file" accept="image/*" class="custom-form-control" :hidden="true" id="imgFile" @change=uploadImage >
          </div>
          <div class="w-100 text-center" v-else style="padding-right: 1.8rem !important">
            <img
              v-if="previewImage"
              :src="previewImage"
              alt="" class="m-auto cursor-pointer"
              @click="clickEvent()"
              style="max-width: 200px"
            >
            <img
              v-else
              src="@/assets/images/profile/profile.png"
              alt="" class="m-auto cursor-pointer"
              @click="clickEvent()"
            >
            <input type="file" class="custom-form-control" accept="image/*" :hidden="true" id="imgFile" @change=uploadImage >
          </div>

          <!-- form -->
          <b-form
            class="auth-register-form mt-2"
            autocomplete="off"
            @submit.prevent="register"
          >
            <validation-observer
              class="w-100"
              ref="registerForm"
              #default="{invalid}"
            >
              <b-row class="w-100 d-flex">
                <b-col
                  md="6"
                >
                  <!-- First Name -->
                  <b-form-group
                    label-for="fname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      vid="fname"
                      rules="required"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      />
                      <b-form-input
                        id="fname"
                        v-model="form.fname"
                        name="fname"
                        placeholder="First Name*"
                        autocomplete="off"
                        autocorrect="off" 
                        autocapitalize="off"
                        :state="errors.length > 0 ? false:null"
                        class="custom-form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <!-- Last Name -->
                  <b-form-group
                    label-for="lname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      vid="lname"
                      rules="required"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      />
                      <b-form-input
                        id="lname"
                        v-model="form.lname"
                        name="lname"
                        autocomplete="off"
                        autocorrect="off" 
                        autocapitalize="off"
                        placeholder="Last Name*"
                        :state="errors.length > 0 ? false:null"
                        class="custom-form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <!-- password -->
                  <b-form-group
                    label-for="reset-password-new"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|min:8"
                    >
                      <feather-icon
                        icon="LockIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      />
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-new"
                          type="password"
                          v-model="form.password"
                          autocomplete="off"
                          autocorrect="off" 
                          autocapitalize="off"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge custom-form-control"
                          name="reset-password-new"
                          placeholder="Password*"
                        />
                        <!-- <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password1ToggleIcon"
                            @click="togglePassword1Visibility"
                          />
                        </b-input-group-append> -->
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                  <!-- confirm password -->
                  <b-form-group
                    label-for="reset-password-confirm"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <feather-icon
                        icon="LockIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      />
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-confirm"
                          v-model="form.confirm_password"
                          class="form-control-merge custom-form-control"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                          autocorrect="off" 
                          autocapitalize="off"
                          type="password"
                          name="reset-password-confirm"
                          placeholder="Confirm Password*"
                        />
                        <!-- <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                            @click="togglePassword2Visibility"
                          />
                        </b-input-group-append> -->
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                >
                  <!-- Mobile Number -->
                  <b-form-group
                    label-for="phone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="mobile number"
                      vid="phone"
                      rules="required"

                    >
                      <!-- <feather-icon
                        icon="PhoneIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      /> -->
                      <div class="d-flex mt-2">

                        <vue-country-code
                          @onSelect="onSelect"
                          :enabledCountryCode="true"
                          class="text-light"
                        >
                        </vue-country-code>
                        <b-form-input
                          id="phone"
                          v-model="form.phone"
                          name="phone"
                          type="number"
                          autocomplete="off"
                          autocorrect="off" 
                          autocapitalize="off"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Mobile Number*"
                          class="custom-form-control"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                >
                <b-form-group
                    label-for="birthdate"
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="Date of Birth"
                    vid="birthdate"
                    rules="required"
                  >
                      <feather-icon
                        icon="CalendarIcon"
                        size="16"
                        style="margin-bottom: -68px"
                      />
                        <flat-pickr
                          v-model="rec_birthdate"
                          class="form-control custom-form-control"
                          :config="{ dateFormat: 'd/m/Y'}"
                          placeholder="Date of Birth (DD-MM-YYYY)"

                        />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </b-col>
                <b-col
                  md="12"
                >
                  <!-- Institution Code -->
                  <b-form-group
                    label-for="code"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="institution code"
                      vid="code"
                      rules="required"
                    >
                      <feather-icon
                        icon="UnlockIcon"
                        size="16"
                        style="margin-bottom: -70px"
                      />
                      <b-form-input
                        id="code"
                        v-model="form.code"
                        name="code"
                        placeholder="Institution Code*"
                        autocomplete="off"
                        autocorrect="off" 
                        autocapitalize="off"
                        :state="errors.length > 0 ? false:null"
                        class="custom-form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <button
                  type="submit"
                  class="btn btn-primary rounded-pill shadow-none mx-auto mt-3 px-5"
                >
                  Submit
                </button>
              </b-row>
            </validation-observer>
          </b-form>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BFormFile, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BFormDatepicker,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormFile,
    flatPickr,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      file1: null,
      countryCode: null,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      previewImage: null,
      rec_birthdate: null,
      form:{
        fname: null,
        lname: null,
        phone: null,
        password: null,
        confirm_password: null,
        code: null,
        birthdate: null,
        profile_img: null,
        email: localStorage.getItem('email') ? localStorage.getItem('email') : null,
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    clickEvent(){
      document.getElementById('imgFile').click();
    },
    uploadImage(e){
        this.form.profile_img = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.form.profile_img);
        reader.onload = e =>{
            this.previewImage = e.target.result;
        };
    },
    onSelect({name, iso2, dialCode}) {
      this.countryCode = dialCode
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.form.birthdate = this.handleDateEditFormat(this.rec_birthdate)

          this.form.avatar = this.$store.state.avatar ? this.$store.state.avatar.substring(this.$store.state.avatar.lastIndexOf('/') + 1) : ''
          this.form.avatar = this.$store.state.avatar ? this.form.avatar.split('.')[0] : ''
          this.form.email = localStorage.getItem('userEmail')
          
          if(!this.form.profile_img){
            delete this.form.profile_img
          }
          this.form.phone = '+' + this.countryCode + this.form.phone;
          const formData = new FormData();
          for (const [key, value] of Object.entries(this.form)) {
            // if (value) {
              formData.append(key, value);
            // }
          }
          const payload = {query: formData }
          this.$store.dispatch( 'users/register' , payload ).then(res=>{
          if(res.user && res.user.type == 2){
              this.$store.commit('appConfig/UPDATE_SKIN', 'dark')
            }else {
              this.$store.commit('appConfig/UPDATE_SKIN', 'light')
            }
            if(res.user.school_status){
              this.$router.push("dashboard");
            }else{
              this.$router.push("inactive_school")
            }
          }).catch(error => {
              if (error.response) {
                this.$swal({
                  icon: 'error',
                  title: `<h4>${error.response.data.error}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: '#E84185',
                  allowOutsideClick: true
                });
              }
          });
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
// @import '@core/scss/vue/pages/page-auth.scss';
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import '@core/scss/vue/libs/vue-flatpicker.scss';
*{
  font-family: "Inter", sans-serif !important;
}
.logo{
  width: 200px;
}
@media (max-width: 992px) {
  .logo{
    width: 100px;
  }
  .auth-inner{
    display: flex !important;
    justify-content: center !important;
  }
}

.auth-wrapper{
  background: url('../../../assets/images/banner/registrationFormBG.png') no-repeat;
  background-size: cover;
}
.auth-bg{
  background-color: #5a5a5a32 !important;
  color: #fff !important
}
.btn-secondary {
    border-color: #82868b !important;
    background-color: transparent !important;
    border-top: unset !important;
}
.inner-addon{
    position: relative;
}
.inner-addon:hover {
    position: relative;
}
.btn-secondary:hover {
  box-shadow: unset !important;
}
.inner-addon .feather-mail,.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}
.custom-form-control{
  background-color:rgba(0,0,0,0) !important;
  border:0px solid #888 !important;
  height: 50px;
  border-bottom: 1px solid #9CA3AF !important;
  border-radius: 0px;
  padding: 0px 0px 0px 20px;
  color: #fff !important
}

.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  .auth-inner {
    width: 100%;
    position: relative;
  }

  &.auth-v1 {
    align-items: center;
    justify-content: center;
    overflow: hidden; // For v1 scroll for long auth form
    .auth-inner {
      &:before {
        width: 244px;
        height: 243px;
        content: ' ';
        position: absolute;
        top: -54px;
        left: -46px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC');
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      &:after {
        width: 272px;
        height: 272px;
        content: ' ';
        position: absolute;
        bottom: -55px;
        right: -75px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARAAAAEQCAMAAABP1NsnAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABEKADAAQAAAABAAABEAAAAAAQWxS2AAAAwFBMVEUAAAD///+AgICAgP9VVaqqVf+qqv+AgL+AgP9mZsxmZv+ZZv+AgNWAgP9tbdttbf+Sbf+AYN+AgN+AgP9xceNmZv+AZuaAZv90dOh0dP9qav+AauqAav+AgP92dv9tbf+Abe2Abf93Zu53d+6AcO94afCAcfF5a+R5a/JzZuaAZvKAc/J5bed5bfOAaPN6b/R1auqAavR6ZvV6cPV2bOuAbPV7aPZ2be2AbfZ7au17avZ3Zu53b+57a+97a/d4aO9J6CoeAAAAQHRSTlMAAQICAwMDBAQFBQUGBgcHBwgICAkKCgoLCwwMDAwNDg4ODw8QERITExQUFBUVFhcYGBkZGhobHBwdHR4eHx8gJ5uMWwAAA/FJREFUeNrt2G1XEkEYxvHZNk2xHGzdbKFl0cTwgdSkCKzu7/+t4pw6sAjtjIueE/f8r3fMO35nZnbuy5gVGcvfzJe0rnTfGI+MggGJRUZnbpPIhJKt88nU53JnFULvyISY6KAv8vPj0vr2rYwiE2Z2B9J+uNYcyyQxwWZvaeGH3G4bMjsvI/kcwTC/V+7kLoahlITzQojP3ZFgsJCh7IJQzpX0QFj4uMiY18eDMZ9bZCF9OQahnK6cm/Y7js0sh/LF3Auv1PlQd3MxbdXYIQspV44EEEAAAWTNDAYYkKdJbNMsLzYueZbaZ2iM46RVbHBaiZ9Js+nHEdli42N9XuSen5hGp1CQTuOJQDRsD99N4gMSpYWapNH6IJo83CIeILZQFesEaber79NCWRoukOpNEnW0gXQqD81w6ACxhbrYde7VuFCYeA2QRCNIsgZISyNIqz6IyhPjOjNVIFYniK3dmKU6QdLaJUimEySrDZLrBMlrgxRKU7sxCw/EMe0CAggggADySJCqxixIkKpNEh6IozELD8RxjQACCCCAAPJIkKrGLEgQXqqAAEJjxrQLCCCAAEJjRmNGY8a0CwgggABCYwYIfQgggNCYMe0CAggggNCY0ZjRmDHtAgIIIIAAQmNGHwIIIDRmTLuAAAIIIDRmNGY0Zky7gAACCCCA0JjRhwACCI0Z0y4ggAACCI0ZjRmNGdMuIIAAAgggNGb0IYAAQmPGtAsIIIAAQmNGY0ZjxrQLCCCAAAIIjRl9CCCA0Jgx7QICCCCA0JjRmNGYMe0CAggggABCY0YfAgggNGZMu4AAAgggNGY0ZjRmTLuAAAIIIIDQmNGHAAIIjRnTLiCAAAIIjRmNGY0ZIEy7gAACCCA0ZvQhgABCY8a0CwgggABCY0ZjBgiNGdMuIIAAAgiN2f/Sh+Q6PfLaIJlOkKw2SKoTJK3dmFmdILb2tBvrBIlrg5iWRo+WqQ+SaARJ1gCJAzsxThCN16p1vNurGjNjoo42j07kAHFskoY2kEbl33U0ZgoPjXW+Rl0gkarnahqtDaJKxMPDDWIiNafGenh4gExvVhXfmk7Da6L1AVGxSby2h6MxK79Zk42ea1pJbJ48sU2zDezQ8iy1z6BBwoyjMQsvXp8YQAAhgADilRfyy+wf8WqZZUfGZihvgZiB3FybC+kCUU5XLkAo50C+gbBQdUzkAIVyejIAYfFTI1solHP2HgNCnHn5AYNy4jvpoVB6fVzL91cwzLJ9Lfd7S0jhehxO5H5/yePr1W6gHonI7fJ5ORSR/n6Q2yQanq763zuXU5LJZRKiyD/W9/pjkdPZz0/yJ8fqVyry+qQZDMjJKoDfy8bRVhHhQTwAAAAASUVORK5CYII=');
        z-index: -1;
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &.auth-v2 {
    align-items: flex-start;
    .auth-inner {
      height: 100vh;
      overflow-y: auto; // For v2 scroll for long auth form
      height: calc(var(--vh, 1vh) * 100);
    }
    .brand-logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
      margin: 0;
      z-index: 1;
    }
  }


  .brand-logo {
    display: flex;
    justify-content: center;
    margin: 1rem 0 2rem 0;
    .brand-text {
      font-weight: 600;
    }
  }

  .auth-footer-btn {
    .btn {
      padding: 0.6rem !important;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.vue-country-select{
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
}
.country-code{
  color: #b5b5b5 !important;
}
</style>
